import style from "./loader.module.css"

export const Loader = () => {
    return (
        <div className={style.overlay}>
            <div className={style.loader}>
                <img className={style.loaderImg} src="../../assets/icons/hello-albania.jpeg" alt="Loading..." />
            </div>
        </div>
    )
}